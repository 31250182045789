<template>
  <div id="browser">
    <img class="wrong-browser" src="../assets/img/browser_not_support.png" />
    <h2>您的浏览器版本过低</h2>
    <p>
      抱歉，您正在使用的浏览器内核版本过低，微软已不再提供技术支持，<br />
      为避免可能存在的安全隐患，请更换浏览器访问，以获得更优秀的用户体验。
    </p>
    <div class="recommended-browser">
      <h2>推荐浏览器：</h2>
      <div class="browser-list">
        <div>
          <img src="../assets/img/edge.png" />
          <a class="button" href="https://www.microsoft.com/zh-cn/edge" target="_blank">Edge 浏览器</a>
        </div>
        <div>
          <img src="../assets/img/chrome.png" />
          <a class="button" href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">Chrome 浏览器</a>
        </div>
        <div>
          <img src="../assets/img/qqbrowser.png" />
          <a class="button" href="https://browser.qq.com/" target="_blank">QQ浏览器</a>
        </div>
        <div>
          <img src="../assets/img/360browser.png" />
          <a class="button" href="https://browser.360.cn/" target="_blank">360浏览器</a>
        </div>
      </div>
    </div>
    <p style="text-align: left">
      如果您正在使用的是双核浏览器，比如QQ浏览器、搜狗浏览器、猎豹浏览器、360浏览器等，您可以使用浏览器的极速模式来继续访问。
    </p>
    <div class="recommended-browser">
      <h2>相关资讯：</h2>
      <div class="news-list">
        <div>
          <a target="_blank" href="https://k.sina.com.cn/article_1644114654_61ff32de0200195q5.html">微软官宣：IE浏览器桌面程序将于2022年6月15日退役</a>
          <p>新浪网 - 2021年05月21日</p>
        </div>
        <div>
          <a target="_blank" href="https://docs.microsoft.com/zh-cn/lifecycle/announcements/internet-explorer-11-support-end-dates">Microsoft 应用和服务终止对 Internet Explorer 11 的支持</a>
          <p>微软官网 - 2020年12月14日</p>
        </div>
        <div>
          <a target="_blank" href="https://tech.sina.com.cn/digi/2019-02-09/doc-ihrfqzka4488212.shtml">微软网络安全主管：大家别再用IE浏览器了</a>
          <p>新浪网 - 2019年02月09日</p>
        </div>
      </div>
    </div>
    <h5>技术支持：广东金质信息技术有限公司</h5>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
#browser {
  width: 790px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;

  & > h5 {
    font-size:14px;
    font-weight:400;
    color: #C0C4CC;
    padding-top: 50px;
  }

  .wrong-browser {
    width: 160px;
	  height: 120px;
    margin: 50px auto 20px;
  }

  & > h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 20px;
    color: #363636;
  }

  & > p {
    font-size: 16px;
    line-height: 30px;
    color: #999999;
  }

  .recommended-browser {
    margin-top: 50px;
    text-align: left;

    & > h2 {
      font-size: 24px;
      line-height: 20px;
      color: #363636;
      margin-bottom: 30px;
    }

    .browser-list {
      display: flex;
      margin-bottom: 30px;

      .button {
        width: 160px;
        height: 42px;
        background: #e6f2fb;
        border: 1px solid #99c9ef;
        line-height: 42px;
        font-weight: 600;
        text-align: center;
        color: #0078d7;
        text-align: center;

        &:hover {
          background: #0078D7;
          color: #fff;
        }

      }

      & > div {
        flex: 1;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        & > img {
          display: block;
          width: 100px;
          height: 100px;
          margin: 0 auto 20px;
        }

        .el-button {
          width: 160px;
        }

        & > a {
          display: block;
          text-decoration: none;
        }


      }
    }

    .news-list {
      & > div {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;

        & > a {
          font-size: 18px;
          color: #0078D7;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        & > p {
          font-size: 16px;
          color: #999999;
          margin-top: 10px;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>